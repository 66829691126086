<template>
	<div class="main-content">
		<add-modal
			:is-edit="is.editingProduct"
			:product="localProduct"
			:show="is.showing.addModal"
			@close="closeAddModal"
			@on-product-created="(districtId) => onFilter('district_id', districtId)"
		/>
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('Premium Product', 'Premium Product')"
			:title="$t('Premium Product List', 'Premium Product List')"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="filteredData"
			:search-enabled="false"
			:search-on-enter="false"
			:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
			:total-rows="productList ? filteredData.length : 0"
			min-height="250px"
			mode="client"
			@on-search="onSearch"
		>
			<template #actionleft>
				<ktv-button v-if="isAllowedToAdd" :text="$t('Add', 'Add')" icon="add" @click="showAddModal" />
			</template>
			<template #actionright>
				<ktv-select
					v-model="params.district_id"
					:options="districtOptions"
					label=""
					style="margin-bottom: 0 !important; width: 15rem"
					form-group-style="margin-bottom: 0!important;"
					@input="onFilter('district_id', $event)"
				/>
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-item @click="showUpdateModal(props.row)">
							<img :src="icons.edit" class="imgicon" />{{ $t("Update", "Update") }}
						</b-dropdown-item>
						<b-dropdown-item @click="showDeleteDialog(props.row)">
							<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'productImage'">
					<div v-if="props.row.productImage" class="p-4">
						<img :src="props.row.productImage" />
					</div>
					<span v-else>-</span>
				</span>
				<span v-else-if="props.column.field === 'salePrice'">{{ props.row.salePrice | Rupiah }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { filter, findWhere, map, reject } from "underscore"
	import { KtvButton, KtvDialog, KtvSelect, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import AddModal from "./add/modals/_Add"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import editIcon from "@/assets/images/icon/iconedit.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		edit: editIcon,
		view: viewIcon,
	}

	export default {
		name: "PremiumPointsProductList",
		metaInfo: {
			title: "Product List Premium Points",
		},
		components: { AddModal, KtvButton, KtvDialog, KtvSelect, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "premiumProductId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "productImage",
						html: true,
						label: "Image",
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "premiumName",
						label: "Name",
						thClass: "text-left",
					},
					{
						field: "productUnitName",
						label: "Unit Info",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "salePrice",
						label: "Price (/unit)",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "maximumRecomendation",
						label: "Max Recommendation",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
				],
				deleteProductId: null,
				districtOptions: [],
				icons: icons,
				is: {
					editingProduct: false,
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
					},
				},
				localProduct: {
					dateModified: null,
					districtId: null,
					maximumRecomendation: null,
					modifiedBy: null,
					partnerEntityId: null,
					price: null,
					priority: null,
					productDescription: null,
					productId: null,
					productImage: null,
					productName: null,
					unitInfo: null,
				},
				params: {
					district_id: null,
					partner_entity_id: null,
				},
				searchText: null,
			}
		},
		computed: {
			...mapGetters({
				entityOrganizationId: "AUTHDATA/userEntityOrganizationID",
				premiumPointActions: "AUTHDATA/premiumPointActions",
			}),
			...mapState("OTHERS", ["districtList"]),
			...mapState("PREMIUMPOINT", ["productList", "productTotalRows"]),
			filteredData() {
				return this.searchText
					? filter(this.productList, (product) => {
						const premiumName = String(product.premiumName).toLowerCase()
						const premiumProductId = String(product.premiumProductId).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return premiumName.indexOf(searchText) > -1 || premiumProductId.indexOf(searchText) > -1
					})
					: this.productList
			},
			isAllowedToAdd() {
				return this.getActionPermission("add")
			},
			isEntityAndDistrictLoaded() {
				return this.params.partner_entity_id && this.params.district_id
			},
		},
		watch: {
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			districtOptions: {
				deep: true,
				handler() {
					if (this.districtOptions && this.districtOptions.length > 0) {
						this.params = {
							...this.params,
							district_id: this.districtOptions[0].value || 0,
						}
					}
				},
				immediate: true,
			},
			entityOrganizationId: {
				handler() {
					this.params = {
						...this.params,
						partner_entity_id: this.entityOrganizationId,
					}
				},
				immediate: true,
			},
			isEntityAndDistrictLoaded: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getData()
					}
				},
				immediate: true,
			},
		},
		created() {
			this.getDistrictList()
		},
		beforeDestroy() {
			this.searchText = null
		},
		methods: {
			...mapActions({
				deleteProduct: "PREMIUMPOINT/deleteProduct",
				getDistrictList: "OTHERS/getDistrictList",
				getProductList: "PREMIUMPOINT/getProductList",
			}),
			closeAddModal() {
				this.localProduct = {
					dateModified: null,
					districtId: null,
					maximumRecomendation: null,
					modifiedBy: null,
					partnerEntityId: null,
					price: null,
					priority: null,
					productDescription: null,
					productId: null,
					productImage: null,
					productName: null,
					unitInfo: null,
				}

				this.is = {
					...this.is,
					editingProduct: false,
				}

				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			closeDeleteDialog() {
				this.deleteProductId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deleteProduct({
					districtId: this.params.district_id,
					partnerEntityId: this.params.partner_entity_id,
					productId: this.deleteProductId,
				}).then(() => {
					this.closeDeleteDialog()
				})
			},
			getActionPermission(name = null) {
				return findWhere(this.premiumPointActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getProductList(this.params)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: district.districtId,
					}
				})

				this.districtOptions = reject(this.districtOptions, (district) => {
					return !district.text || !district.value
				})
			},
			onFilter(by = null, queryText = null) {
				if (by) {
					this.updateParams({
						[by]: queryText,
					})

					this.getData()
				}
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			showDeleteDialog({ premiumProductId }) {
				this.deleteProductId = premiumProductId

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showUpdateModal({ maximumRecomendation, premiumName, premiumProductId, priority, productImage, productUnitName, salePrice }) {
				this.localProduct = {
					...this.localProduct,
					districtId: this.params.district_id,
					maximumRecomendation: maximumRecomendation,
					price: salePrice,
					priority: priority,
					productId: premiumProductId,
					productImage: productImage,
					productName: premiumName,
					unitInfo: productUnitName,
				}

				this.is = {
					...this.is,
					editingProduct: true,
				}

				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
