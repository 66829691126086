<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" no-close-on-backdrop no-close-on-esc :title="$t('Premium Product Add', 'Premium Product Add')" @close="close()">
			<template #content>
				<validation-observer ref="form_add_product_premium_point" tag="form" @submit.prevent="save">
					<ktv-select
						v-model="localProduct.districtId"
						:disabled="isEdit"
						:label="$t('District', 'District')"
						:options="districtOptions"
						rules="required"
					/>
					<ktv-select
						v-model="localProduct.productName"
						rules="required"
						:options="productNameOptions"
						:reduce="false"
						:taggable="true"
						:label="$t('Name', 'Name')"
					/>
					<ktv-select
						v-model="localProduct.unitInfo"
						rules="required"
						:reduce="false"
						:taggable="true"
						:options="unitInfoOptions"
						:label="$t('Unit Info', 'Unit Info')"
					/>
					<ktv-select v-model="localProduct.priority" rules="required" :options="priorityOptions" :label="$t('Priority', 'Priority')" />
					<ktv-input v-model="localProduct.price" rules="required" :label="$t('Price (/unit)', 'Price (/unit)')" prepend="Rp" type="currency" />
					<ktv-input
						v-model="localProduct.maximumRecomendation"
						rules="required"
						:label="$t('Max Recommendation', 'Max Recommendation')"
						type="number"
					/>
					<ktv-image-upload
						:current-file="localProduct.productImage"
						:file="productPhoto"
						:label="$t('Photo', 'Photo')"
						:rules="!localProduct.productImage ? 'required' : ''"
						class="col-md-12 my-2"
						@on-added="onAddedPhoto"
					/>
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Reset', 'Reset')" color="light" :loading="is.loading" class="mr-2" @click="reset()" />
					<ktv-button :text="$t('Save', 'Save')" :disabled="is.loading" :loading="is.loading" @click="save()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvImageUpload, KtvInput, KtvModal, KtvSelect } from "@/components"
	import { findWhere, map, reject } from "underscore"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "PremiumPointsProductAdd",
		metaInfo: {
			title: "Product Premium Points - Add",
		},
		components: { KtvButton, KtvImageUpload, KtvInput, KtvModal, KtvSelect, ValidationObserver },
		props: {
			isEdit: {
				default: false,
				required: true,
				type: Boolean,
			},
			product: {
				default: () => {
					return {
						dateModified: null,
						districtId: null,
						maximumRecomendation: null,
						modifiedBy: null,
						partnerEntityId: null,
						price: null,
						priority: null,
						productDescription: null,
						productId: null,
						productImage: null,
						productName: null,
						unitInfo: null,
					}
				},
				required: false,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-product-created"],
		data() {
			return {
				districtOptions: [],
				is: {
					loading: false,
					showingModal: false,
				},
				localProduct: {
					dateModified: null,
					districtId: null,
					maximumRecomendation: null,
					modifiedBy: null,
					partnerEntityId: null,
					price: null,
					priority: null,
					productDescription: null,
					productId: null,
					productImage: null,
					productName: null,
					unitInfo: null,
				},
				priorityOptions: [],
				productNameOptions: [],
				productPhoto: null,
				unitInfoOptions: [],
			}
		},
		computed: {
			...mapGetters({
				entityOrganizationId: "AUTHDATA/userEntityOrganizationID",
			}),
			...mapState("OTHERS", ["districtList", "priorityList", "productNameList", "unitInfoList"]),
		},
		watch: {
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			priorityList: {
				deep: true,
				handler() {
					this.getPriorityOptions()
				},
				immediate: true,
			},
			product: {
				deep: true,
				handler(product) {
					this.localProduct = Object.assign({}, product)
				},
				immediate: true,
			},
			productNameList: {
				deep: true,
				handler() {
					this.getProductNameOptions()
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
			unitInfoList: {
				deep: true,
				handler() {
					this.getUnitInfoOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getDistrictList()
			this.getProductNameList()
			this.getUnitInfoList()
			this.getPriorityList()
		},
		mounted() {
			this.reset()
		},
		methods: {
			...mapActions({
				createProduct: "PREMIUMPOINT/createProduct",
				getDistrictList: "OTHERS/getDistrictList",
				getPriorityList: "OTHERS/getPriorityList",
				getProductList: "PREMIUMPOINT/getProductList",
				getProductNameList: "OTHERS/getProductNameList",
				getUnitInfoList: "OTHERS/getUnitInfoList",
				updateProduct: "PREMIUMPOINT/updateProduct",
				uploadProductImage: "PREMIUMPOINT/uploadProductImage",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}
				this.reset()

				this.$emit("close")
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: district.districtId,
					}
				})

				this.districtOptions = reject(this.districtOptions, (district) => {
					return !district.text || !district.value
				})
			},
			getPriorityOptions() {
				this.priorityOptions = map(this.priorityList, (priority) => {
					return {
						text: priority.text,
						value: priority.value,
					}
				})
			},
			getProductNameOptions() {
				this.productNameOptions = this.productNameList
			},
			getUnitInfoOptions() {
				this.unitInfoOptions = this.unitInfoList
			},
			onAddedPhoto(file) {
				this.productPhoto = file
			},
			reset() {
				this.localProduct = Object.assign({}, this.product)
				this.productPhoto = null
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				this.$refs.form_add_product_premium_point.validate().then((valid) => {
					if (valid) {
						self.getProductList({
							district_id: self.localProduct.districtId,
							partner_entity_id: self.entityOrganizationId,
						})
							.then((productList) => {
								let isAllowedToSelectPriority = false
								const matchedPriority = findWhere(productList, { priority: self.localProduct.priority })

								if (self.isEdit) {
									isAllowedToSelectPriority = matchedPriority.premiumProductId === self.localProduct.productId
								} else {
									isAllowedToSelectPriority = !matchedPriority
								}

								if (isAllowedToSelectPriority) {
									if (self.productPhoto) {
										self.uploadProductImage(self.productPhoto)
											.then((photoURL) => {
												self.localProduct = {
													...self.localProduct,
													productImage: photoURL,
												}

												if (!self.isEdit) {
													self.createProduct({
														...self.localProduct,
													})
														.then((districtId) => {
															self.$emit("on-product-created", districtId)
															self.close()
														})
														.catch(() => {
															self.close()
														})
												} else {
													self.updateProduct({
														...self.localProduct,
													})
														.then((districtId) => {
															self.$emit("on-product-created", districtId)
															self.close()
														})
														.catch(() => {
															self.close()
														})
												}
											})
											.catch(() => {
												self.close()
											})
									} else if (!self.isEdit) {
										self.createProduct({
											...self.localProduct,
										})
											.then((districtId) => {
												self.$emit("on-product-created", districtId)
												self.close()
											})
											.catch(() => {
												self.close()
											})
									} else {
										self.updateProduct({
											...self.localProduct,
										})
											.then((districtId) => {
												self.$emit("on-product-created", districtId)
												self.close()
											})
											.catch(() => {
												self.close()
											})
									}
								} else {
									self.$swal(
										`${this.$t("Failed", "Failed")}!`,
										this.$t(
											"Priority already exist in the selected district. Something bad happen when saving data. Try again later",
											"Priority already exist in the selected district. Something bad happen when saving data. Try again later",
										),
										"error",
									)
								}
							})
							.catch(() => {
								self.close()
							})
					} else {
						self.is = {
							...self.is,
							loading: false,
						}
					}
				})
			},
		},
	}
</script>
